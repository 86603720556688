import React from 'react';
import {
    Button,
    Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const styles = (theme) => ({
    tag: {
        backgroundColor: '#00bcd4',
        borderRadius: 4,
        boxSizing: 'border-box',
        color: '#fff',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.4,
        lineHeight: 4.75,
        marginRight: theme.spacing.unit * 3,
        minWidth: 64,
        padding: '10px 16px',
        textTransform: 'uppercase',
        verticalAlign: 'middle',
    },
    alert: {
        backgroundColor: '#ffffff',
        borderColor: '#ff0000',
        borderWidth: 2,
        borderStyle: 'solid',
        borderRadius: 4,
        boxSizing: 'border-box',
        color: '#ff0000',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.4,
        lineHeight: 1.2,
        // marginLeft: theme.spacing.unit * 3,
        width: '100%',
        padding: '10px 16px',
        textTransform: 'uppercase',
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
    },
    badgeContainer: {
        marginTop: theme.spacing.unit * 4,
        marginBottom: theme.spacing.unit * 2,
    },
    alertContainer: {
        // marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 4,
        textAlign: 'left',
    },
    alertIcon: {
        marginRight: 8,
        color: '#ff0000',
    },
    alertText: {
        '&:not(:last-of-type)': {
            marginBottom: theme.spacing.unit * 2,
        },
    },
});

class ContactSaleBadges extends React.Component {
    state = {
    };

    render() {
        const {
            classes,
            contact,
            contactSalesLoading,
            contactLoading,
            currentSale,
        } = this.props;

        const alerts = [
            {
                show: !contactLoading && !contactSalesLoading && currentSale.status === 'INCOMPLETE',
                label: 'SALE INCOMPLETE',
            },
            {
                show: !contactLoading && contact.forbidAgreement,
                label: 'SALES PROHIBITED',
            },
            {
                show: !contactLoading
                && currentSale.status !== 'CANCELLED'
                && currentSale.status !== 'COMPLETE'
                && currentSale.plan.saleAmount > currentSale.plan.paidAmount,
                label: 'DO NOT DELIVER : Not Fully Paid',
            },
        ];

        return (
            <>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        {alerts.filter((alert) => alert.show).length > 0
                            ? (
                                <div className={classes.alertContainer}>
                                    <div className={classes.alert}>
                                        {alerts.filter((alert) => alert.show).map((alert) => (
                                            <div className={classes.alertText} key={alert.label}>
                                                <FontAwesomeIcon icon={faExclamationTriangle} inverse color="red" className={classes.alertIcon} />
                                                {alert.label}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )
                            : null}
                    </Grid>
                </Grid>
            </>
        );
    }
}

export default withStyles(styles)(ContactSaleBadges);
