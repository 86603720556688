import React from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import { sortBy } from 'lodash';
import fetch, { fetchJson2 } from '../../utilities/fetch';

const { REACT_APP_API_SERVER } = process.env;

const styles = (theme) => ({
    dialogContentContainer: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    dialogContent: {
        minWidth: 600,
        paddingBottom: 16,
    },
    dialogButton: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 4,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        margin: '0px 0px 0px 0px !important',
        padding: '8px 24px',
    },
    dialogDivider: {
        marginBottom: theme.spacing.unit * 2,
        marginLeft: -24,
        marginRight: -24,
        marginTop: theme.spacing.unit * 2,
    },
    disabled: {
        opacity: 0.26,
    },
    dialogExpiredCheckBox: {
        textAlign: 'right',
    },
    checkBox: {
        fontSize: 16,
    },
    dialogTitle: {
        marginBottom: theme.spacing.unit * 2,
    },
});

class ProductCreateDialog extends React.Component {
    state = {
        createProductData: {
            name: '',
        },
        disableCreateProductSaveButton: false,
        errors: [],
    };

    componentDidMount() {
        this.setFormDefaults();
    }

    setFormDefaults = () => {
        this.setState({
            createProductData: {
                manufacturerCode: '',
                modelNo: '',
                productTypeCode: '',
            },
        });
    };

    updateCreateProductData = (event) => {
        const { name, value } = event.target;

        let formattedValue;
        let addressData;
        switch (name) {
        case 'address1':
        case 'address2':
        case 'townCity':
        case 'county':
        case 'postcode':
        case 'country':
            addressData = {
                ...this.state.createProductData.address,
                [name]: value,
            };
            break;
        default:
            formattedValue = value;
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            createProductData: {
                ...state.createProductData,
                [name]: formattedValue,
                address: {
                    ...state.createProductData.address,
                    ...addressData,
                },
            },
        }));
    };

    handleCreateProduct = () => {
        this.setState({
            disableCreateProductSaveButton: true,
        });
        const payload = this.state.createProductData;
        this.createProduct(payload).then(() => {
            this.setState({
                disableCreateProductSaveButton: false,
            });
            this.props.handleCloseCreateProductDialogClick();
            this.props.enqueueSnackbar('Manufacturer/Supplier Created', { variant: 'success' });
        }).catch(() => {
            this.props.enqueueSnackbar('Create Manufacturer/Supplier Failed', { variant: 'error' });
            this.setState({
                disableCreateProductSaveButton: false,
            });
        });
    };

    updateCreateProductDataCheckbox = (name) => (event) => {
        const newState = !!event.target.checked;
        this.setState((state) => ({
            createProductData: {
                ...state.createProductData,
                [name]: newState,
            },
        }));
    };

    createProduct = async (payload) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/Product/`, {
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
              && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            const newProduct = {
                ...body.Product,
                histories: [
                    body.history,
                ],
            };
            this.props.passNewProduct(newProduct);
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    render() {
        const {
            classes,
            handleCloseCreateProductDialogClick,
            manufacturers,
            productLoading,
            productTypes,
            showCreateProductDialog,
        } = this.props;

        const {
            disableCreateProductSaveButton,
            createProductData,
            errors,
        } = this.state;

        return (
            <>
                {!productLoading
                    ? (
                        <Dialog
                            open={showCreateProductDialog}
                            onClose={() => this.props.handleCloseCreateProductDialogClick()}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            className={classes.newManufacturerDialog}
                        >
                            <DialogContent className={classes.dialogContent}>
                                <div className={classes.dialogContentContainer}>
                                    <>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            className={classes.dialogTitle}
                                        >
                                            Create Product
                                        </Typography>
                                        <Grid container spacing={0}>
                                            <Grid item xs={10}>
                                                <FormControl
                                                    className={classes.menuFormControl}
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    error={!!errors.manufacturerCode}
                                                >
                                                    <InputLabel htmlFor="manufacturerCode" className={classes.inputLabel}>Manufacturer</InputLabel>
                                                    <Select
                                                        keyboard="true"
                                                        input={(
                                                            <OutlinedInput
                                                                onChange={this.updateCreateProductData}
                                                                name="manufacturerCode"
                                                                id="manufacturerCode"
                                                            />
                                                        )}
                                                        value={createProductData.manufacturerCode || ''}
                                                    >
                                                        {sortBy(manufacturers, 'description').map((manufacturer, i) => (
                                                            <MenuItem
                                                                key={i}
                                                                value={manufacturer.code}
                                                            >
                                                                {manufacturer.name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                    {errors.manufacturerCode && (
                                                        <FormHelperText>{errors.manufacturerCode}</FormHelperText>
                                                    )}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Button
                                                    className={classes.fieldAddButton}
                                                    onClick={this.handleCreateManufacturerClick}
                                                >
                                                    NEW

                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={0}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    className={classes.formControl}
                                                    name="modelNo"
                                                    id="modelNo"
                                                    onChange={this.updateCreateProductData}
                                                    value={createProductData.modelNo}
                                                    label="Model Number"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    error={!!errors.modelNo}
                                                    helperText={errors.modelNo}
                                                />
                                            </Grid>
                                            <Grid container spacing={0}>
                                                <Grid item xs={10}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.productTypeCode}
                                                    >
                                                        <InputLabel htmlFor="productTypeCode" className={classes.inputLabel}>Product Type</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateCreateProductData}
                                                                    name="productTypeCode"
                                                                    id="productTypeCode"
                                                                />
                                                            )}
                                                            value={createProductData.productTypeCode || ''}
                                                        >
                                                            {sortBy(productTypes, 'name').map((productType, i) => (
                                                                <MenuItem
                                                                    key={i}
                                                                    value={productType.code}
                                                                >
                                                                    {productType.description}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.productTypeCode && (
                                                            <FormHelperText>{errors.productTypeCode}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Button
                                                        className={classes.fieldAddButton}
                                                        onClick={this.handleCreateManufacturerClick}
                                                    >
                                                        NEW

                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl component="fieldset" className={classes.formControl}>
                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={(
                                                                <Checkbox
                                                                    name="manufacturer"
                                                                    id="manufacturer"
                                                                    onChange={this.updateCreateProductDataCheckbox('manufacturer')}
                                                                    checked={createProductData.manufacturer}
                                                                    value={createProductData.manufacturer}
                                                                />
                                                            )}
                                                            classes={{ label: classes.checkBox }}
                                                            label="Manufacturer"
                                                        />
                                                        <FormControlLabel
                                                            control={(
                                                                <Checkbox
                                                                    name="supplier"
                                                                    id="supplier"
                                                                    onChange={this.updateCreateProductDataCheckbox('supplier')}
                                                                    checked={createProductData.supplier}
                                                                    value={createProductData.supplier}
                                                                />
                                                            )}
                                                            classes={{ label: classes.checkBox }}
                                                            label="Supplier"
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        {createProductData.supplier
                                            ? (
                                                <>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="address1"
                                                                id="address1"
                                                                onChange={this.updateCreateProductData}
                                                                value={(createProductData.address || {}).address1}
                                                                label="Address Line 1"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.address1}
                                                                helperText={errors.address1}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="address2"
                                                                id="address2"
                                                                onChange={this.updateCreateProductData}
                                                                value={(createProductData.address || {}).address2}
                                                                label="Address Line 2"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                error={!!errors.address2}
                                                                helperText={errors.address2}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="townCity"
                                                                id="townCity"
                                                                onChange={this.updateCreateProductData}
                                                                value={(createProductData.address || {}).townCity}
                                                                label="Town/City"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.townCity}
                                                                helperText={errors.townCity}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="county"
                                                                id="county"
                                                                onChange={this.updateCreateProductData}
                                                                value={(createProductData.address || {}).county}
                                                                label="County"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.county}
                                                                helperText={errors.county}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="postcode"
                                                                id="postcode"
                                                                onChange={this.updateCreateProductData}
                                                                value={(createProductData.address || {}).postcode}
                                                                label="Postcode"
                                                                margin="normal"
                                                                variant="outlined"
                                                                required
                                                                error={!!errors.postcode}
                                                                helperText={errors.postcode}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="country"
                                                                id="country"
                                                                onChange={this.updateCreateProductData}
                                                                value={(createProductData.address || {}).country}
                                                                label="Country"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.country}
                                                                helperText={errors.country}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Divider className={classes.dialogDivider} />
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="telephoneNumber"
                                                                id="telephoneNumber"
                                                                onChange={this.updateCreateProductData}
                                                                value={createProductData.telephoneNumber}
                                                                label="Telephone Number"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.telephoneNumber}
                                                                helperText={errors.telephoneNumber}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="faxNumber"
                                                                id="faxNumber"
                                                                onChange={this.updateCreateProductData}
                                                                value={createProductData.faxNumber}
                                                                label="Fax Number"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                error={!!errors.faxNumber}
                                                                helperText={errors.faxNumber}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="emailAddress"
                                                                id="emailAddress"
                                                                onChange={this.updateCreateProductData}
                                                                value={createProductData.emailAddress}
                                                                label="Email Address"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.emailAddress}
                                                                helperText={errors.emailAddress}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="accountReference"
                                                                id="accountReference"
                                                                onChange={this.updateCreateProductData}
                                                                value={createProductData.accountReference}
                                                                label="Account Reference"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.accountReference}
                                                                helperText={errors.accountReference}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="ourAccountReference"
                                                                id="ourAccountReference"
                                                                onChange={this.updateCreateProductData}
                                                                value={createProductData.ourAccountReference}
                                                                label="Our Account Reference"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.ourAccountReference}
                                                                helperText={errors.ourAccountReference}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                className={classes.formControl}
                                                                name="paymentTerms"
                                                                id="paymentTerms"
                                                                onChange={this.updateCreateProductData}
                                                                value={createProductData.paymentTerms}
                                                                label="Payment Terms"
                                                                margin="normal"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                error={!!errors.paymentTerms}
                                                                helperText={errors.paymentTerms}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                </>
                                            )
                                            : ''}
                                        <Grid
                                            container
                                            spacing={0}
                                            direction="column"
                                            className={classes.dialogExpiredCheckBox}
                                        >
                                            <Grid item xs={12}>
                                                <FormControlLabel
                                                    control={(
                                                        <Checkbox
                                                            name="expired"
                                                            id="expired"
                                                            onChange={this.updateCreateProductDataCheckbox('expired')}
                                                            checked={createProductData.expired}
                                                            value={createProductData.expired}
                                                        />
                                                    )}
                                                    classes={{ label: classes.checkBox }}
                                                    label="Expired"
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            spacing={0}
                                            direction="column"
                                            alignItems="center"
                                            justify="center"
                                        >
                                            <Grid item xs={12}>
                                                <Button
                                                    className={classes.dialogButton}
                                                    type="submit"
                                                    onClick={this.handleCreateProduct}
                                                    disabled={disableCreateProductSaveButton
                                                || (!createProductData.manufacturer && !createProductData.supplier)}
                                                >
                                                    SAVE
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </>
                                </div>
                            </DialogContent>
                            <DialogActions className={classes.dialogActions}>
                                <Button
                                    onClick={() => this.props.handleCloseCreateProductDialogClick()}
                                    color="primary"
                                    autoFocus
                                >
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    )
                    : ''}
            </>

        );
    }
}

export default withSnackbar(withStyles(styles)(ProductCreateDialog));
