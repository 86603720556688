const titaliseWithHyphen = (string) => {
    const formattedString = string.replace(/_/g, ' ');
    if (!formattedString) return null;
    return formattedString.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ')
        .split('-')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join('-');
};

export default titaliseWithHyphen;
