import React from 'react';
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    Menu,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    TextField,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCaretRight, faCaretLeft, faExclamationTriangle, faImages,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import TruckIcon from '@material-ui/icons/Markunread';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { withSnackbar } from 'notistack';
import { compact } from 'lodash';
import { DatePicker } from 'material-ui-pickers';
import fetch, { fetchJson, fetchJson2 } from '../../utilities/fetch';
import sleep from '../../utilities/sleep';
import HybridTextField from '../hybridTextField';
import {
    titles,
    counties,
    countries,
    adHocCompanies,
    adHocPaymentMethods,
} from '../../utilities/menuOptions';
import titalise from '../../utilities/titalise';
import { visitPointsArray } from '../../constants/arrays';
import CuttingSheet from './cuttingSheet';
import './cuttingSheet.css';
import AgreementPaymentSummary from './agreementPaymentSummary';

const { REACT_APP_API_SERVER } = process.env;

const styles = (theme) => ({
    scheduler: {
        // width: 2500,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        minHeight: 650,
    },
    day: {
        backgroundColor: '#ebecf0',
        padding: 8,
        minWidth: 160,
    },
    dayPicker: {
        '&:hover': {
            backgroundColor: '#00bcd4',
            cursor: 'pointer',
        },
    },
    visit: {
        // backgroundColor: 'red',
        // width: 200,
        // margin: 16,
        // minHeight: 80,
        marginTop: 8,
        padding: '43px 8px 24px 8px',
        position: 'relative',
        textAlign: 'left',
    },
    // points1: {
    //     minHeight: 88,
    // },
    // points2: {
    //     minHeight: 184,
    // },
    // points3: {
    //     minHeight: 280,
    // },
    // points4: {
    //     minHeight: 376,
    // },
    // points5: {
    //     minHeight: 472,
    // },
    // points6: {
    //     minHeight: 568,
    // },
    points1: {
        minHeight: 156,
    },
    points2: {
        minHeight: 277,
    },
    points3: {
        minHeight: 398,
    },
    points4: {
        minHeight: 519,
    },
    points5: {
        minHeight: 640,
    },
    points6: {
        minHeight: 761,
    },
    dateDay: {
        fontSize: 16,
        fontWeight: 600,
        color: '#fff',
    },
    dateDate: {
        fontSize: 12,
        color: '#fff',
    },
    invite: {
        // background: `repeating-linear-gradient(
        //     45deg,
        //     #fff,
        //     #fff 10px,
        //     #ff8080 10px,
        //     #ff8080 20px)`,
        backgroundColor: '#d5f0f1',
    },
    zeroPointDay: {
        background: `repeating-linear-gradient(
            45deg,
            #627c8a,
            #627c8a 10px,
            #344955 10px,
            #344955 20px)`,
    },
    dateContainer: {
        backgroundColor: '#344955',
        padding: 8,
        textAlign: 'center',
        position: 'relative',
        minHeight: 73,
    },
    gridContainer: {
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    topBar: {
        backgroundColor: theme.palette.secondary.main,
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 1,
        padding: theme.spacing.unit * 2,
    },
    topBarLeft: {
        textAlign: 'left',
        fontWeight: 500,
        fontSize: 18,
    },
    topBarRight: {
        textAlign: 'right',
    },
    titlePipe: {
        color: '#fff',
        paddingLeft: theme.spacing.unit * 1,
        paddingRight: theme.spacing.unit * 1,
    },
    titleBranch: {
        fontWeight: 'normal',
    },
    titlePostcodeZones: {
        fontWeight: 'normal',
    },
    weekNavButton: {
        padding: 0,
    },
    caretIcon: {
        fontSize: 30,
    },
    titleContainer: {
        marginTop: 4,
    },
    engineerTitleName: {
        '&:hover': {
            backgroundColor: theme.palette.secondary.main,
            cursor: 'pointer',
        },
    },
    visitPostcodeZone: {
        fontSize: 12,
        backgroundColor: '#6d9097',
        position: 'absolute',
        bottom: 4,
        left: 4,
        padding: 4,
        borderRadius: 4,
        color: '#fff',
        fontWeight: 600,
        minWidth: 22,
        textAlign: 'center',
    },
    visitPoints: {
        fontSize: 12,
        position: 'absolute',
        bottom: 4,
        right: 4,
        padding: 4,
        borderRadius: 11,
        color: '#fff',
        fontWeight: 600,
        minWidth: 22,
        textAlign: 'center',
    },
    assignmentButton: {
        fontSize: 12,
        position: 'absolute',
        top: 4,
        right: 4,
        padding: 4,
        borderRadius: 11,
        color: '#fff',
        fontWeight: 600,
        minWidth: 22,
        textAlign: 'center',
    },
    totalDailyPoints: {
        fontSize: 12,
        // backgroundColor: '#009688',
        position: 'absolute',
        bottom: 4,
        right: 4,
        padding: 4,
        borderRadius: 11,
        color: '#fff',
        fontWeight: 600,
        minWidth: 22,
        textAlign: 'center',
    },
    totalInstallationAmount: {
        fontSize: 12,
        // backgroundColor: '#009688',
        position: 'absolute',
        bottom: 4,
        left: 4,
        padding: 4,
        borderRadius: 4,
        color: '#fff',
        fontWeight: 600,
        minWidth: 22,
        textAlign: 'center',
    },
    postcodeClose: {
        backgroundColor: '#ffbf00',
    },
    postcodeMatch: {
        backgroundColor: '#32cd32',
    },
    progressContainer: {
        marginBottom: theme.spacing.unit * 3,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing.unit * 3,
        width: 60,
    },
    progress: {
        opacity: '.5',
    },
    sunday: {
        marginTop: theme.spacing.unit * 4,
    },
    engineerMenuBranch: {
        color: '#a3a3a3',
        marginTop: -5,
    },
    amber: {
        backgroundColor: '#ffbf00',
        color: '#727272',
    },
    green: {
        backgroundColor: '#009688',
        color: '#fff',
    },
    pointsBlue: {
        backgroundColor: '#2196f3',
    },
    highlightBlue: {
        backgroundColor: '#c6edff',
        cursor: 'pointer',
    },
    red: {
        backgroundColor: '#f44336',
        color: '#fff',
    },
    limeGreen: {
        backgroundColor: '#32cd32',
    },
    installationFee: {
        fontSize: 12,
        position: 'absolute',
        bottom: 4,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '100%',
        padding: 4,
        borderRadius: 11,
        color: '#000',
        fontWeight: 600,
        minWidth: 22,
        textAlign: 'center',
    },
    visitTopBar: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        backgroundColor: '#bababa',
        borderRadius: '4px 4px 0px 0px',
        minHeight: 35,
        padding: 8,
        fontSize: 14,
        fontWeight: 500,
    },
    // visitTopBarContentWrapper: {
    //     position: 'relative',

    // },
    visitTopBarLeft: {
        textAlign: 'left',
        marginTop: 1,
    },
    visitTopBarRight: {
        textAlign: 'right',
    },
    visitItemList: {
        fontSize: 14,
        marginLeft: 16,
        color: '#7b7b7b',
        width: 'inherit',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    visitName: {
        fontWeight: 500,
        marginBottom: 4,
    },
    menu: {
        // width: 175,
    },
    menuPostcodeZone: {
        backgroundColor: '#32cd32',
        fontSize: 12,
        paddingLeft: 4,
        paddingRight: 4,
        borderRadius: 4,
        color: '#fff',
        fontWeight: 600,
        height: 24,
        marginLeft: 16,
    },
    // doNotDeliver: {
    //     color: '#ff0000',
    //     position: 'absolute',
    //     left: 0,
    //     top: 0,
    //     width: '100%',
    //     textAlign: 'center',
    //     fontSize: 26,
    //     fontWeight: 900,
    //     opacity: 1,
    //     textShadow: '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
    //     // transform: 'rotateY(315deg)',
    // },
    adHocVisit: {
        boxShadow: '0 0 0 3px #ffbf00, 0 0 0 2px #ffbf00 inset',
        border: '2px solid transparent',
        borderRadius: 4,
        display: 'inline-block',
        padding: '5px 2px',
        color: '#ffbf00',
        fontSize: 24,
        textTransform: 'uppercase',
        textAlign: 'center',
        opacity: 0.6,
        fontWeight: 900,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'rotate(-5deg) translate(-50%,-50%)',
        minWidth: '80%',
    },
    notChecked: {
        boxShadow: '0 0 0 3px #ff0000, 0 0 0 2px #ff0000 inset',
        border: '2px solid transparent',
        borderRadius: 4,
        display: 'inline-block',
        padding: '5px 2px',
        color: '#ff0000',
        fontSize: 24,
        textTransform: 'uppercase',
        textAlign: 'center',
        opacity: 1,
        fontWeight: 900,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'rotate(-5deg) translate(-50%,-50%)',
        minWidth: '80%',
    },
    notCheckedVisitItem: {
        opacity: '0.2 !important',
    },
    doNotDeliver: {
        boxShadow: '0 0 0 3px #f44336, 0 0 0 2px #f44336 inset',
        border: '2px solid transparent',
        borderRadius: 4,
        display: 'inline-block',
        padding: '5px 2px',
        color: '#f44336',
        fontSize: 24,
        textTransform: 'uppercase',
        textAlign: 'center',
        opacity: 1,
        fontWeight: 900,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'rotate(-5deg) translate(-50%,-50%)',
        minWidth: '80%',
        textShadow: '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff',
    },
    doNotDeliverWrapper: {
        // height: 150,
    },
    inviteWarning: {
        boxShadow: '0 0 0 3px #ffbf00, 0 0 0 2px #ffbf00 inset',
        border: '2px solid transparent',
        borderRadius: 4,
        display: 'inline-block',
        padding: '5px 2px',
        color: '#ffbf00',
        fontSize: 24,
        textTransform: 'uppercase',
        textAlign: 'center',
        opacity: 0.6,
        fontWeight: 900,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'rotate(-5deg) translate(-50%,-50%)',
        minWidth: '80%',
    },
    inviteWarningWrapper: {
        // height: 150,
    },
    '@keyframes flashRed': {
        '0%': { backgroundColor: '#FF0000' },
        '50%': { backgroundColor: '#000000' },
    },
    pointsWarning: {
        animation: '$flashRed 1s linear',
    },
    adHocNote: {
        color: '#7b7b7b',
    },
    infoButton: {
        padding: 0,
        marginTop: -1,
    },
    infoIcon: {
        fontSize: 19,
    },
    dialogActions: {
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        padding: '8px 24px',
        margin: '0px 0px 0px 0px !important',
    },
    dialogContentContainer: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 2,
    },
    dialogButton: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    dialogContent: {
        paddingBottom: 16,
        minWidth: 390,
    },
    dialogDivider: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginLeft: -24,
        marginRight: -24,
    },
    menuDivider: {
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
        marginLeft: -24,
        marginRight: -24,
    },
    adHocMenuIcon: {
        position: 'absolute',
        top: 16,
        right: 16,
    },
    dialogTitle: {
        marginBottom: 24,
    },
    inputLabel: {
        backgroundColor: '#fff',
        paddingLeft: 8,
        marginLeft: -5,
        paddingRight: 8,
    },
    dialogDivider: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit * 3,
        marginLeft: -24,
        marginRight: -24,
    },
    lookupButton: {
        marginLeft: 24,
        marginTop: 24,
    },
    menuFormControl: {
        marginBottom: theme.spacing.unit * 1,
        marginTop: theme.spacing.unit * 2,
    },
    visitItem: {
        width: '100%',
        borderRadius: '4px',
        minHeight: 35,
        padding: 8,
        fontSize: 14,
        marginBottom: 8,
    },
    cuttingSheet: {
        display: 'none',
    },
    adHocSignature: {
        height: 120,
    },
    alertTitleIcon: {
        marginTop: 2,
        color: '#ff0000',
        marginLeft: 8,
    },
    imagesTitleIcon: {
        marginTop: 2,
        color: '#000000',
    },
    alertIcon: {
        marginRight: 8,
        color: '#ff0000',
    },
    alertContainer: {
        // marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 1,
        textAlign: 'left',
    },
    visitItemAlertContainer: {
        marginTop: theme.spacing.unit * 0.5,
        // marginBottom: theme.spacing.unit * 1,
        textAlign: 'left',
    },
    alert: {
        backgroundColor: '#ffffff',
        borderColor: '#ff0000',
        borderWidth: 2,
        borderStyle: 'solid',
        borderRadius: 4,
        boxSizing: 'border-box',
        color: '#ff0000',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
        fontWeight: 500,
        letterSpacing: 0.4,
        lineHeight: 1.2,
        // marginLeft: theme.spacing.unit * 3,
        width: '100%',
        padding: '10px 16px',
        textTransform: 'uppercase',
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
    },
    visitItemAlert: {
        backgroundColor: '#ffffff',
        borderColor: '#ff0000',
        borderWidth: 2,
        borderStyle: 'solid',
        borderRadius: 4,
        boxSizing: 'border-box',
        color: '#ff0000',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 9.5,
        fontWeight: 500,
        letterSpacing: 0.4,
        lineHeight: 1.2,
        // marginLeft: theme.spacing.unit * 3,
        width: '100%',
        padding: '4px 4px',
        textTransform: 'uppercase',
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
    },
    visitComplete: {
        opacity: 0.3,
    },
    locationIconContainer: {
        marginTop: 12,
        textAlign: 'right',
        color: '#fffff',
    },
});

class EngineerSchedule extends React.Component {
    state = {
        adHocAnchor: null,
        adHocVisitForm: {},
        anchorEl: null,
        branchesLoading: true,
        currentAdHocVisit: null,
        currentVisitCode: null,
        currentVisitDate: null,
        cuttingSheetVisits: null,
        disableEditAdHocSaveButton: false,
        engineersLoading: true,
        engineerVisitsLoading: true,
        errors: [],
        lookupAddresses: [],
        lookupAddressesEl: null,
        showAdHocDialog: false,
        showCancelVisitDialog: false,
        showCreateAdHocDialog: false,
        showOnlyBranchEngineers: true,
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            branchCode: props.branchCode,
            engineerContactCode: props.engineerContactCode,
            postcodeZone: props.postcodeZone,
            startDate: props.date,
            // postcodeArea: props.postcodeArea,
            // postcodeDistrict: props.postcodeDistrict,
        };
    }

    componentDidMount = async () => {
        this.setFormDefaults();
        await this.getDates(this.state.startDate);
        await this.fetchEngineers();
        if (!this.state.engineerContactCode) {
            this.setState((state) => ({
                engineerContactCode: ((state.engineers.find((engineer) => engineer.branchCode === this.state.branchCode)) || {}).code || state.engineers[0].code,
            }));
        }
        await this.fetchBranches();
        this.fetchEngineerVisits(this.state.engineerContactCode, this.state.startDate, this.state.endDate);
    };

    componentDidUpdate = () => {
        if (this.state.cuttingSheetVisits !== null) {
            window.print();
            this.setState({ cuttingSheetVisits: null });
        }
    };

    setFormDefaults = () => {
        this.setState({
            adHocVisitForm: {
                address1: '',
                address2: '',
                townCity: '',
                county: '',
                country: '',
                postcode: '',
                scheduledVisitDateTime: moment.tz(moment(), 'Europe/London').format('YYYY-MM-DD'),
            },
        });
    };

    fetchEngineerVisits = async (contactCode, startDate, endDate) => {
        this.setState({
            engineerVisitsLoading: true,
        });

        const params = {
            // endDate: this.state.endDate,
            // startDate: this.state.startDate,
            startDate,
            endDate,
        };
        const url = new URL(`${REACT_APP_API_SERVER}v2/engineer/${contactCode}/visits`);
        url.search = new URLSearchParams(params).toString();
        let response;
        let body;
        try {
            [response] = await Promise.all([fetch(url), sleep(500)]);
        } catch (e) {
            console.log(e);
        }

        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            this.setState({
                engineerVisits: body,
                engineerVisitsLoading: false,
            });
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    fetchEngineers = async () => {
        this.setState({
            engineersLoading: true,
        });

        const url = new URL(`${REACT_APP_API_SERVER}v2/engineers`);
        let response;
        let body;
        try {
            [response] = await Promise.all([fetch(url), sleep(500)]);
        } catch (e) {
            console.log(e);
        }

        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            this.setState({
                engineers: body,
                engineersLoading: false,
            });
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    fetchBranches = async () => {
        this.setState({
            branchesLoading: true,
        });

        const params = {
            endDate: this.state.endDate,
            startDate: this.state.startDate,
        };
        const url = new URL(`${REACT_APP_API_SERVER}v2/branches`);
        url.search = new URLSearchParams(params).toString();
        let response;
        let body;
        try {
            [response] = await Promise.all([fetch(url), sleep(500)]);
        } catch (e) {
            console.log(e);
        }

        if (response.headers.get('content-type')
        && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            this.setState({
                branches: body,
                branchesLoading: false,
            });
            break;
        }
        default: {
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    getDates = (date) => {
        const currentDate = moment.tz(moment(), 'Europe/London');
        const startDate = date
            ? moment(date).startOf('isoWeek').format('YYYY-MM-DD')
            : moment(currentDate).startOf('isoWeek').format('YYYY-MM-DD');
        const endDate = moment(startDate).add(6, 'days').format('YYYY-MM-DD');
        const dateArray = new Array();
        let currentLoopDate = startDate;
        while (currentLoopDate <= endDate) {
            dateArray.push(currentLoopDate);
            currentLoopDate = moment(currentLoopDate).add(1, 'days').format('YYYY-MM-DD');
        }
        this.setState({
            dateArray,
            endDate,
            startDate,
        });
    };

    nextWeekClick = async () => {
        const newStartDate = moment(this.state.startDate).add(1, 'weeks').format('YYYY-MM-DD');
        await this.getDates(newStartDate);
        await this.fetchEngineerVisits(this.state.engineerContactCode, this.state.startDate, this.state.endDate);
    };

    lastWeekClick = async () => {
        const newStartDate = moment(this.state.startDate).subtract(1, 'weeks').format('YYYY-MM-DD');
        await this.getDates(newStartDate);
        await this.fetchEngineerVisits(this.state.engineerContactCode, this.state.startDate, this.state.endDate);
    };

    handleEngineerClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleEngineerClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleCreateAdHocClick = () => {
        this.setFormDefaults();
        this.setState({
            showCreateAdHocDialog: true,
        });
    };

    handleCuttingSheetClick = (date) => {
        this.setState((state) => ({
            cuttingSheetVisits: state.engineerVisits.filter((engineerVisit) => engineerVisit.scheduledEngineerContactCode === state.engineerContactCode && moment(engineerVisit.scheduledVisitDateTime).format('YYYY-MM-DD') === date),
        }));
        console.log('>>> cuttingSheetVisits: ', this.state.engineerVisits.filter((engineerVisit) => engineerVisit.scheduledEngineerContactCode === this.state.engineerContactCode && moment(engineerVisit.scheduledVisitDateTime).format('YYYY-MM-DD') === date));
    };

    handleCreateAdHocCloseDialogClick = () => {
        this.setState({
            showCreateAdHocDialog: false,
        });
    };

    handleAdHocMenuClick = (event) => {
        this.setState({
            adHocAnchor: event.currentTarget,
        });
    };

    handleAdHocMenuClose = () => {
        this.setState({
            adHocAnchor: null,
        });
    };

    handleEngineerChange = (contactCode) => {
        this.setState({
            engineerContactCode: contactCode,
            anchorEl: null,
        });
        this.fetchEngineerVisits(contactCode, this.state.startDate, this.state.endDate);
    };

    handleMouseOverVisit = (visitCode) => {
        this.setState({
            currentVisitCode: visitCode,
        });
    };

    handleMouseLeaveVisit = () => {
        this.setState({
            currentVisitCode: null,
        });
    };

    handleMouseOverPaymentSummary = (event) => {
        event.stopPropagation();
        this.setState({
            currentVisitCode: null,
        });
    };

    handleMouseOverDate = (visitDate) => {
        this.setState({
            currentVisitDate: visitDate,
        });
    };

    handleMouseLeaveDate = () => {
        this.setState({
            currentVisitDate: null,
        });
    };

    handleVisitClick = (contactCode, visitCode) => {
        if (this.state.currentVisitCode) {
            window.open(`/contact/${contactCode}?visitCode=${visitCode}`);
        }
    };

    handleCloseAdHocDialogClick = () => {
        this.setState({
            currentAdHocVisit: null,
            showAdHocDialog: false,
        });
    };

    handleCloseCuttingSheetDialogClick = () => {
        this.setState({
            showCuttingSheetDialog: false,
        });
    };

    handleAdHocDialogClick = (visitCode) => {
        console.log('>>> visitCode:', visitCode);
        this.setState((state) => ({
            currentAdHocVisit: state.engineerVisits.find((engineerVisit) => engineerVisit.code === visitCode),
            showAdHocDialog: true,
        }));
    };

    handleCancelVisitClick = () => {
        this.setState({
            adHocAnchor: false,
            showCancelVisitDialog: true,
        });
    };

    handleCloseCancelVisitDialogClick = () => {
        this.setState({
            showCancelVisitDialog: false,
        });
    };

    handleCancelVisit = () => {
        this.setState({
            disableCancelVisitYesButton: true,
        });
        const visitCode = this.state.currentAdHocVisit.code;
        this.cancelVisit(visitCode).then((visit) => {
            const newVisits = compact(this.state.engineerVisits.map((engineerVisit) => (engineerVisit.code === visit.code ? null : engineerVisit)));

            this.setState({
                disableCancelVisitYesButton: false,
                engineerVisits: newVisits,
                showAdHocDialog: false,
                showCancelVisitDialog: false,
            });
            this.props.enqueueSnackbar('Visit Cancelled. Customer Notification Sent.', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.setState({
                disableCancelVisitYesButton: false,
            });
            this.props.enqueueSnackbar('Cancel Visit Failed.', { variant: 'error' });
        });
    };

    handleMarkVisitComplete = () => {
        const visitCode = this.state.currentAdHocVisit.code;
        const payload = {
            status: 'COMPLETE',
        };
        this.updateVisit(visitCode, payload).then((visit) => {
            const newVisits = compact(this.state.engineerVisits.map((engineerVisit) => (engineerVisit.code === visit.code ? null : engineerVisit)));

            this.setState({
                engineerVisits: newVisits,
                showAdHocDialog: false,
                adHocAnchor: null,
            });
            this.props.enqueueSnackbar('Visit Marked Complete.', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Mark Visit Complete Failed.', { variant: 'error' });
        });
    };

    updateAdHocVisitData = (event) => {
        const { name, value } = event.target;
        let formattedValue;
        switch (name) {
        case 'postcode':
            formattedValue = value.toUpperCase();
            break;
        case 'emailAddress':
            formattedValue = value.toLowerCase();
            break;
        case 'mobileTelephoneNumber':
            formattedValue = value.replace(/[^0-9$.]/g, '');
            break;
        case 'installationAmount': formattedValue = value.replace(/^(\d+\.?\d?\d?)?.*/, '$1');
            break;
        case 'county':
        case 'country':
        case 'engineerContactCode':
        case 'adHocNote':
        case 'visitPoints':
            formattedValue = value;
            break;
        default:
            formattedValue = value;
            // formattedValue = titalise(value);
            break;
        }
        event.target.value = formattedValue;

        this.setState((state) => ({
            adHocVisitForm: {
                ...state.adHocVisitForm,
                [name]: formattedValue,
            },
        }));
    };

    handleAdHocVisitDateTimeChange = (chosenDate) => {
        this.setState((state) => ({
            adHocVisitForm: {
                ...state.adHocVisitForm,
                scheduledVisitDateTime: moment(chosenDate).format('YYYY-MM-DD'),
            },
        }));
    };

    lookupPostcode = async (event) => {
        const { currentTarget } = event;
        const { postcode } = this.state.adHocVisitForm;

        fetchJson(`${REACT_APP_API_SERVER}v2/postcode/${postcode}/addresses`)
            .then((response) => {
                this.setState({
                    lookupAddressesEl: currentTarget,
                    lookupAddresses: response,
                });
            });
    };

    handleCloseLookupAddressesMenu = () => {
        this.setState({
            lookupAddressesEl: null,
        });
    };

    handleLookupAddressClick = (i) => {
        this.setState((state) => ({
            adHocVisitForm: {
                ...state.adHocVisitForm,
                address1: state.lookupAddresses[i].address1,
                address2: state.lookupAddresses[i].address2,
                townCity: state.lookupAddresses[i].townCity,
                county: state.lookupAddresses[i].county,
                country: state.lookupAddresses[i].country,
                postcode: state.lookupAddresses[i].postcode,
            },
            lookupAddressesEl: null,
        }));
    };

    handleSaveNewAdHocVisit = () => {
        const payload = JSON.stringify({
            ...this.state.adHocVisitForm,
        });
        console.log('>>> adHocVisitForm:', this.state.adHocVisitForm);
        this.createAdHocVisit(payload).then((visit) => {
            if (
                visit.scheduledVisitDateTime >= this.state.startDate
                && visit.scheduledVisitDateTime <= this.state.endDate
                && visit.scheduledEngineerContactCode === this.state.engineerContactCode
            ) {
                this.setState((state) => ({
                    engineerVisits: [
                        ...state.engineerVisits,
                        visit,
                    ],
                }));
            }
            this.setState((state) => ({
                showCreateAdHocDialog: false,
            }));
            this.props.enqueueSnackbar('Ad Hoc Visit Created.', { variant: 'success' });
        }).catch(() => {
            console.log(this.props.errors);
            this.props.enqueueSnackbar('Create Ad Hoc Visit Failed.', { variant: 'error' });
        });
    };

    createAdHocVisit = async (adHocVisit) => {
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/adHocVisit/`, {
                body: adHocVisit,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            return body;
        }
        default: {
            console.log('>>> Throwing error');
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    cancelVisit = async (visitCode) => {
        console.log('>>> Cancel visit: ', visitCode);
        let response;
        let body;

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/visit/${visitCode}`, {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'DELETE',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            return body.visit;
        }
        default: {
            console.log('>>> Throwing error');
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    updateVisit = async (visitCode, params) => {
        console.log('>>> Cancel visit: ', visitCode);
        let response;
        let body;
        const formattedPayload = JSON.stringify({
            ...params,
        });

        try {
            response = await fetch(`${REACT_APP_API_SERVER}v2/visit/${visitCode}`, {
                body: formattedPayload,
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'PATCH',
            });
        } catch (e) {
            // something went really wrong; timeout/ blocked by client etc
            // debugger;
            console.log(e);
        }

        if (response.headers.get('content-type')
          && response.headers.get('content-type').search('application/json') >= 0) {
            body = await response.json();
        }

        switch (response.status) {
        case 200: {
            return body.visit;
        }
        default: {
            console.log('>>> Throwing error');
            throw new Error({ error: 'Generic API error' });
        }
        }
    };

    render() {
        const {
            classes,
        } = this.props;
        const {
            adHocAnchor,
            adHocVisitForm,
            anchorEl,
            branchCode,
            branches,
            branchesLoading,
            currentAdHocVisit,
            currentVisitCode,
            currentVisitDate,
            cuttingSheetVisits,
            dateArray,
            disableEditAdHocSaveButton,
            engineerContactCode,
            engineerVisits,
            engineerVisitsLoading,
            engineers,
            engineersLoading,
            errors,
            lookupAddresses,
            lookupAddressesEl,
            // postcodeArea,
            // postcodeDistrict,
            postcodeZone,
            showAdHocDialog,
            showCancelVisitDialog,
            showCreateAdHocDialog,
            showCuttingSheetDialog,
        } = this.state;

        let currentEngineer;
        let currentEngineerBranch;
        if (!engineersLoading && !branchesLoading) {
            currentEngineer = engineers.find((engineer) => engineer.code === engineerContactCode);
            currentEngineerBranch = branches.find((branch) => branch.code === currentEngineer.engineer.branchCode);
        }

        // const test = (
        //     <div>
        //         hello
        //     </div>
        // );

        // {test}

        // const Test2 = ({ qty }) => {
        //     return (
        //         <div>
        //             hello
        //         </div>
        //     );
        // }

        // <Test2
        //     qty = {2}
        // />

        return (
            <div className={['screen-only', classes.gridContainer].join(' ')}>
                {!engineersLoading && !branchesLoading
                    ? (
                        <>
                            <Grid
                                container
                                spacing={0}
                                className={classes.scheduler}
                            >
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <Paper
                                        className={classes.topBar}
                                        elevation={0}
                                    >
                                        <Grid
                                            container
                                            spacing={0}
                                        >
                                            <Grid
                                                item
                                                xs={9}
                                                className={classes.topBarLeft}
                                            >
                                                <div className={classes.titleContainer}>
                                                    <span
                                                        onClick={this.handleEngineerClick}
                                                        className={classes.engineerTitleName}
                                                    >
                                                        {`${currentEngineer.firstname} ${currentEngineer.surname}`}
                                                    </span>
                                                    <span className={classes.titlePipe}>|</span>
                                                    <span className={classes.titleBranch}>
                                                        {currentEngineerBranch.name}
                                                    </span>
                                                    {currentEngineer.engineer.postcodeZones.length > 0
                                                        ? (
                                                            <>
                                                                <span className={classes.titlePipe}>|</span>
                                                                <span className={classes.titlePostcodeZones}>
                                                                    {currentEngineer.engineer.postcodeZones.reduce((acc, postcodeZone) => `${acc}${acc ? ', ' : ''}${postcodeZone}`, '')}
                                                                </span>
                                                            </>
                                                        )
                                                        : ''}
                                                </div>
                                                <Menu
                                                    id="simple-menu"
                                                    anchorEl={anchorEl}
                                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={this.handleEngineerClose}
                                                    classes={{ paper: classes.menu }}
                                                    disableAutoFocusItem
                                                >
                                                    {engineers.filter((engineer) => engineer.branchCode === (branchCode || engineer.branchCode)).map((engineer) => {
                                                        if (engineer.code !== engineerContactCode) {
                                                            return (
                                                                <MenuItem disabled={false} key={engineer.code} onClick={() => this.handleEngineerChange(engineer.code)}>
                                                                    <Grid
                                                                        container
                                                                        spacing={0}
                                                                    >
                                                                        <Grid
                                                                            item
                                                                            xs={8}
                                                                        >
                                                                            <div>
                                                                                {`${engineer.firstname} ${engineer.surname}  `}
                                                                            </div>
                                                                            {!branchCode
                                                                                ? (<div className={classes.engineerMenuBranch}>{branches.find((branch) => branch.code === engineer.engineer.branchCode).name}</div>)
                                                                                : ''}
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            xs={4}
                                                                        >
                                                                            <>
                                                                                {engineer.engineer.postcodeZones.find((engineerPostcodeZone) => engineerPostcodeZone === postcodeZone)
                                                                                    ? (
                                                                                        <div className={classes.menuPostcodeZone}>
                                                                                            <div>
                                                                                                {postcodeZone}
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                    : ''}
                                                                            </>
                                                                        </Grid>
                                                                    </Grid>
                                                                </MenuItem>
                                                            );
                                                        }
                                                    })}
                                                </Menu>

                                            </Grid>
                                            {/* {
                                                (() => {
                                                    return (
                                                        <div>

                                                        </div>
                                                    )
                                                })()
                                            } */}
                                            <Grid
                                                item
                                                xs={3}
                                                className={classes.topBarRight}
                                            >
                                                {!this.props.visitFromSchedule
                                                    ? (
                                                        <Button
                                                            variant="outlined"
                                                            onClick={this.handleCreateAdHocClick}
                                                        >
                                                            + AD HOC
                                                        </Button>
                                                    )
                                                    : null }
                                                <Button
                                                    onClick={this.lastWeekClick}
                                                    className={classes.weekNavButton}
                                                    size="small"
                                                >
                                                    <FontAwesomeIcon icon={faCaretLeft} className={classes.caretIcon} />
                                                </Button>
                                                <Button
                                                    onClick={this.nextWeekClick}
                                                    size="small"
                                                    className={classes.weekNavButton}
                                                >
                                                    <FontAwesomeIcon icon={faCaretRight} className={classes.caretIcon} />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Paper>

                                </Grid>
                                <Grid
                                    container
                                    spacing={8}
                                    className={classes.scheduler}
                                >
                                    {/* ************************************************************************************ */}
                                    {dateArray.map((date, index) => {
                                        let dateVisits = null;
                                        let totalDailyAdHocFee = null;
                                        let totalDailyInstallationAmount = null;
                                        let totalDailyAmount = null;
                                        let totalDailyPoints = null;
                                        let maxPoints = null;
                                        let maxPointsRaw = null;
                                        if (!engineerVisitsLoading) {
                                            dateVisits = engineerVisits.filter((visit) => moment(visit.scheduledVisitDateTime).format('YYYY-MM-DD') === date) || [];
                                            totalDailyAdHocFee = dateVisits.reduce((acc, visit) => acc + visit.adHocFee, 0);
                                            totalDailyInstallationAmount = dateVisits.map((visit) => visit.visitItems.reduce((acc, visitItem) => acc + visitItem.agreementItem.installationAmount, 0)).reduce((acc, installationAmount) => acc + installationAmount, 0);
                                            totalDailyAmount = totalDailyAdHocFee + totalDailyInstallationAmount;
                                            totalDailyPoints = dateVisits.reduce((acc, visit) => (acc + visit.points), 0);
                                            maxPointsRaw = ((currentEngineer.engineer.workingWeek.find((day) => day.weekDay === moment(date).format('dddd').toUpperCase())) || {}).maxPoints;
                                            maxPoints = typeof maxPointsRaw === 'undefined' ? null : maxPointsRaw;
                                        }
                                        if (index <= 4) {
                                            return (
                                                <Grid
                                                    item
                                                    xs={2}
                                                    // spacing={8}
                                                    key={date}
                                                >
                                                    <Paper
                                                        className={classNames(
                                                            `${classes.day}`,
                                                            { [classes.highlightBlue]: (this.props.visitFromSchedule && currentVisitDate === date) },
                                                        )}
                                                        elevation={1}
                                                        onMouseOver={this.props.visitFromSchedule ? (() => this.handleMouseOverDate(date)) : undefined}
                                                        onMouseLeave={this.props.visitFromSchedule ? (this.handleMouseLeaveDate) : undefined}
                                                        onClick={this.props.visitFromSchedule ? (() => this.props.visitFromSchedule(engineerContactCode, moment(date).format('YYYY-MM-DD 00:00'))) : undefined}
                                                    >
                                                        <Paper
                                                            className={classNames(
                                                                classes.dateContainer,
                                                                {
                                                                    [classes.zeroPointDay]: maxPoints === 0,
                                                                },
                                                                {
                                                                    [classes.limeGreen]: !engineerVisitsLoading && dateVisits.find((dateVisit) => dateVisit.contact.address.postcodeZone === postcodeZone),
                                                                },
                                                            )}
                                                            elevation={0}
                                                        >
                                                            <div className={classes.dateDay}>
                                                                {moment(date).format('dddd')}
                                                            </div>
                                                            <div className={classes.dateDate}>
                                                                {moment(date).format('DD/MM/YYYY')}
                                                            </div>
                                                            {!engineerVisitsLoading && dateVisits.length > 0
                                                                ? (
                                                                    <IconButton onClick={() => this.handleCuttingSheetClick(date)} className={classes.assignmentButton} aria-label="Cut List"><AssignmentIcon className={classes.assignmentIcon} /></IconButton>
                                                                )
                                                                : ''}
                                                            {!engineerVisitsLoading
                                                                ? (
                                                                    <div className={classNames(
                                                                        classes.totalInstallationAmount,
                                                                        { [classes.amber]: !currentEngineer.engineer.targetDailyAmount },
                                                                        {
                                                                            [classes.red]:
                                                                            currentEngineer.engineer.targetDailyAmount
                                                                            && totalDailyAmount < currentEngineer.engineer.targetDailyAmount,
                                                                        },
                                                                        {
                                                                            [classes.green]:
                                                                            currentEngineer.engineer.targetDailyAmount
                                                                            && totalDailyAmount >= currentEngineer.engineer.targetDailyAmount,
                                                                        },
                                                                    )}
                                                                    >
                                                                        £
                                                                        {!engineerVisitsLoading && totalDailyAmount.toFixed(2)}
                                                                    </div>
                                                                )
                                                                : ''}

                                                            {!engineerVisitsLoading
                                                                // && dateVisits.length > 0
                                                                ? (
                                                                    <div
                                                                        className={classNames(
                                                                            classes.totalDailyPoints,
                                                                            {
                                                                                [classes.amber]:
                                                                                    (dateVisits.filter((visit) => !visit.points).length > 0)
                                                                                    || maxPoints === null
                                                                                    || (dateVisits.filter((visit) => !visit.points).length === 0
                                                                                    && totalDailyPoints <= maxPoints),
                                                                            },
                                                                            {
                                                                                [classes.red]:
                                                                                    maxPoints !== null
                                                                                    && dateVisits.filter((visit) => !visit.points).length === 0
                                                                                    && totalDailyPoints > maxPoints,
                                                                            },
                                                                            {
                                                                                [classes.green]:
                                                                                    maxPoints !== null
                                                                                    && dateVisits.filter((visit) => !visit.points).length === 0
                                                                                    && totalDailyPoints === maxPoints,
                                                                            },
                                                                        )}
                                                                    >
                                                                        {
                                                                            dateVisits.filter((visit) => !visit.points).length === 0
                                                                            // && maxPoints
                                                                                ? (totalDailyPoints)
                                                                                : '?'
                                                                        }
                                                                    </div>
                                                                )
                                                                : ''}
                                                        </Paper>
                                                        {/* ************************************************************************************ */}

                                                        {/* ************************** */}
                                                        {!engineerVisitsLoading
                                                            ? engineerVisits.filter((visit) => moment(visit.scheduledVisitDateTime).format('YYYY-MM-DD') === date).map((visit) => (
                                                                <Paper
                                                                    onMouseOver={!this.props.visitFromSchedule && (() => this.handleMouseOverVisit(visit.code))}
                                                                    onMouseLeave={!this.props.visitFromSchedule && (this.handleMouseLeaveVisit)}
                                                                    onClick={visit.type !== 'AD_HOC' && !this.props.visitFromSchedule && (() => this.handleVisitClick(visit.contactCode, visit.code))}
                                                                    className={classNames(
                                                                        { [classes.visit]: true },
                                                                        { [classes.visitComplete]: visit.status === 'COMPLETE' },
                                                                        {
                                                                            [classes.highlightBlue]: currentVisitCode === visit.code && visit.type !== 'AD_HOC',
                                                                        },
                                                                        {
                                                                            [classes.points2]: visit.points == null,
                                                                        },
                                                                        {
                                                                            [classes.points1]: visit.points === 1,
                                                                        },
                                                                        {
                                                                            [classes.points2]: visit.points === 2,
                                                                        },
                                                                        {
                                                                            [classes.points3]: visit.points === 3,
                                                                        },
                                                                        {
                                                                            [classes.points4]: visit.points === 4,
                                                                        },
                                                                        {
                                                                            [classes.points5]: visit.points === 5,
                                                                        },
                                                                        {
                                                                            [classes.points5]: visit.points === 6,
                                                                        },
                                                                    )}
                                                                    elevation={0}
                                                                    key={visit.code}
                                                                >
                                                                    <div className={classes.visitTopBar}>
                                                                        <Grid
                                                                            container
                                                                            spacing={0}
                                                                        >
                                                                            <Grid item xs={9} className={classes.visitTopBarLeft}>
                                                                                {`${visit.contact.firstname} ${visit.contact.surname}`}
                                                                            </Grid>
                                                                            <Grid item xs={3} className={classes.visitTopBarRight}>
                                                                                {visit.type === 'AD_HOC' && !this.props.visitFromSchedule
                                                                                    ? (
                                                                                        // <Grid item xs={2} className={classes.visitTopBarRight}>
                                                                                        <IconButton onClick={() => this.handleAdHocDialogClick(visit.code)} className={classes.infoButton} aria-label="More info"><InfoIcon className={classes.infoIcon} /></IconButton>
                                                                                        // </Grid>
                                                                                    )
                                                                                    : null}
                                                                                {visit.images.length > 0
                                                                                    ? (
                                                                                    // <Grid item xs={2} className={classes.visitTopBarRight}>
                                                                                        <FontAwesomeIcon icon={faImages} inverse color="black" className={classes.imagesTitleIcon} />
                                                                                    // </Grid>
                                                                                    )
                                                                                    : null}
                                                                                {visit.type !== 'AD_HOC'
                                                                    && (((visit.visitItems.map(
                                                                        (visitItem) => (
                                                                            (
                                                                                (visitItem.agreementItem || {}).minimumPreDeliveryPayments
                                                                                > (((visitItem.agreementItem || {}).authorisedPaymentSummary || {}).filter((payment) => payment).length)
                                                                            )
                                                                        ),
                                                                    )) || []).every(Boolean)
                                                                    || !((visit.visitItems.map(
                                                                        (visitItem) => (['ACTIVE', 'COMPLETE'].includes((visitItem.agreementItem || {}).agreementStatus)),
                                                                    )) || []).every(Boolean)
                                                                    || visit.contact.surname == 'Testtest'
                                                                    )
                                                                                    ? (
                                                                                    // <Grid item xs={2} className={classes.visitTopBarRight}>
                                                                                        <FontAwesomeIcon icon={faExclamationTriangle} inverse color="red" className={classes.alertTitleIcon} />
                                                                                    // </Grid>
                                                                                    )
                                                                                    : null}
                                                                            </Grid>
                                                                        </Grid>
                                                                    </div>
                                                                    {visit.type !== 'AD_HOC'
                                                                    && (
                                                                        ((visit.visitItems.map(
                                                                            (visitItem) => (
                                                                                (
                                                                                    (visitItem.agreementItem || {}).minimumPreDeliveryPayments
                                                                                > (((visitItem.agreementItem || {}).authorisedPaymentSummary || {}).filter((payment) => payment).length)
                                                                                )
                                                                            ),
                                                                        )) || []).every(Boolean)
                                                                    || !((visit.visitItems.map(
                                                                        (visitItem) => (['ACTIVE', 'COMPLETE'].includes((visitItem.agreementItem || {}).agreementStatus)),
                                                                    )) || []).every(Boolean)
                                                                    || visit.contact.surname == 'Testtest'
                                                                    )
                                                                        ? (
                                                                            <div className={classes.alertContainer}>
                                                                                <div className={classes.alert}>
                                                                                    {/* <div className={classes.alertText}> */}
                                                                                    <FontAwesomeIcon icon={faExclamationTriangle} inverse color="red" className={classes.alertIcon} />
                                                                                    DO NOT DELIVER
                                                                                    {/* </div> */}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                        : null}
                                                                    {/* <Typography className={classes.visitName}>
                                                                        {`${visit.contact.firstname} ${visit.contact.surname}`}
                                                                    </Typography> */}
                                                                    {visit.visitItems.map((visitItem) => (
                                                                        <div
                                                                            key={visitItem.code}
                                                                            className={classNames(
                                                                                { [classes.visitItem]: true },
                                                                                {
                                                                                    [classes.notCheckedVisitItem]: visitItem.agreementItem.selfMeasure && !visitItem.agreementItem.selfMeasureCheckedByContactCode,
                                                                                },
                                                                                {
                                                                                    [classes.green]: visitItem.agreementItem.product.stockControlType === 'TRACKED' && visitItem.agreementItem.itemCode,
                                                                                },
                                                                                {
                                                                                    [classes.red]: visitItem.agreementItem.product.stockControlType === 'TRACKED' && !visitItem.agreementItem.itemCode,
                                                                                },
                                                                                {
                                                                                    [classes.amber]: visitItem.agreementItem.product.stockControlType === 'COUNT',
                                                                                },
                                                                                {
                                                                                    [classes.notCheckedVisitItem]: visitItem.agreementItem.selfMeasure && !visitItem.agreementItem.selfMeasureCheckedByContactCode,
                                                                                },
                                                                            )}
                                                                        >
                                                                            {/* {console.log('>>> visitItem.code:', visitItem.code)} */}
                                                                            {visitItem.agreementItem.quantity}
                                                                            {' x '}
                                                                            {visitItem.agreementItem.product.modelNo}
                                                                            {visitItem.installationIncluded
                                                                                ? (
                                                                                    <div className={classes.visitItemAlertContainer}>
                                                                                        <div className={classes.visitItemAlert}>
                                                                                            {/* <div className={classes.alertText}> */}
                                                                                            <FontAwesomeIcon icon={faExclamationTriangle} inverse color="red" className={classes.alertIcon} />
                                                                                            Installation Included
                                                                                            {/* </div> */}
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                                : null}
                                                                            {visitItem.agreementItem.selfMeasure
                                                                                ? (
                                                                                    <div className={classes.visitItemAlertContainer}>
                                                                                        <div className={classes.visitItemAlert}>
                                                                                            {/* <div className={classes.alertText}> */}
                                                                                            <FontAwesomeIcon icon={faExclamationTriangle} inverse color="red" className={classes.alertIcon} />
                                                                                            Self-Measure
                                                                                            {/* </div> */}
                                                                                        </div>
                                                                                    </div>
                                                                                )
                                                                                : null}
                                                                            <Grid
                                                                                container
                                                                                spacing={0}
                                                                                className={classes.sectionContainer}
                                                                            >
                                                                                <Grid
                                                                                    item
                                                                                    xs={6}
                                                                                    onMouseOver={this.handleMouseOverPaymentSummary}
                                                                                >
                                                                                    <AgreementPaymentSummary
                                                                                        agreementPaymentSummary={visitItem.agreementItem.authorisedPaymentSummary}
                                                                                        agreementPaymentSummaryLoading={false}
                                                                                        agreementCode={visitItem.agreementItem.agreementCode}
                                                                                    />
                                                                                </Grid>
                                                                                {/* <Grid item xs={6} className={classes.locationIconContainer}>
                                                                                    {visit.type !== 'AD_HOC'
                                                                                    && (visitItem.agreementItem.item || {}).branchType == 'Vehicle'
                                                                                        ? (
                                                                                            <TruckIcon />
                                                                                        )
                                                                                        : null}
                                                                                </Grid> */}
                                                                            </Grid>
                                                                            <Grid
                                                                                container
                                                                                spacing={0}
                                                                                // className={classes.sectionContainer}
                                                                            >
                                                                                <Grid
                                                                                    item
                                                                                    xs={12}
                                                                                />
                                                                                {/* {(visitItem.agreementItem.item || {}).branch} */}
                                                                            </Grid>
                                                                        </div>
                                                                    ))}
                                                                    {visit.type === 'AD_HOC'
                                                                        ? (
                                                                            <>
                                                                                <div className={classes.adHocPaymentMethod}>
                                                                                    {`* ${visit.adHocPaymentMethod} *`}
                                                                                </div>
                                                                                <div className={classes.adHocNote}>
                                                                                    {visit.adHocNote}
                                                                                </div>
                                                                            </>
                                                                        )
                                                                        : null}
                                                                    <div
                                                                        className={classNames(
                                                                            classes.visitPostcodeZone,
                                                                            {
                                                                                [classes.postcodeMatch]: visit.contact.address.postcodeZone === postcodeZone,
                                                                            },
                                                                            {
                                                                                [classes.amber]: !visit.contact.address.postcodeZone,
                                                                            },
                                                                            // ${visit.contact.address.postcodeArea === postcodeArea ? classes.postcodeClose : ''}
                                                                            // ${visit.contact.address.postcodeDistrict === postcodeDistrict ? classes.postcodeMatch : ''}
                                                                        )}
                                                                    >
                                                                        {visit.contact.address.postcodeZone || '?'}
                                                                    </div>
                                                                    <div className={classes.installationFee}>
                                                                        £
                                                                        {visit.type === 'AD_HOC' ? (visit.adHocFee || 0).toFixed(2) : visit.visitItems.reduce((acc, visitItem) => acc + visitItem.agreementItem.installationAmount, 0).toFixed(2)}
                                                                    </div>
                                                                    <div
                                                                        className={classNames(classes.visitPoints, { [classes.pointsBlue]: visit.points }, { [classes.amber]: !visit.points })}
                                                                    >
                                                                        {visit.points ? visit.points : '?'}
                                                                    </div>
                                                                    {/* {visit.contact.surname.includes('Testtest')
                                                                        ? (
                                                                            <div className={classes.doNotDeliverWrapper}>
                                                                                <div className={classes.doNotDeliver}>
                                                                                    DO NOT DELIVER
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                        : ''} */}
                                                                    {visit.type === 'AD_HOC'
                                                                        ? (
                                                                            <div className={classes.doNotDeliverWrapper}>
                                                                                <div className={classes.adHocVisit}>
                                                                                    {visit.adHocCompany.toUpperCase()}
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                        : ''}
                                                                    {visit.visitItems.filter((visitItem) => (visitItem.agreementItem.selfMeasure && !visitItem.agreementItem.selfMeasureCheckedByContactCode)).length > 0
                                                                        ? (
                                                                            <div className={classes.doNotDeliverWrapper}>
                                                                                <div className={classes.notChecked}>
                                                                                    SM NOT CHECKED
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                        : ''}
                                                                    {visit.status === 'INVITE'
                                                                        ? (
                                                                            <div className={classes.inviteWarningWrapper}>
                                                                                <div className={classes.inviteWarning}>
                                                                                    INVITE
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                        : ''}
                                                                </Paper>
                                                            ))
                                                            : (
                                                                <div className={classes.progressContainer}>
                                                                    <CircularProgress className={classes.progress} variant="indeterminate" />
                                                                </div>
                                                            )}
                                                    </Paper>
                                                </Grid>
                                            );
                                        }
                                    })}
                                    {/* ************************** */}

                                    <Grid
                                        item
                                        xs={2}
                                        // spacing={8}
                                        key={dateArray[5]}
                                    >
                                        <Paper
                                            className={`${classes.day} ${this.props.visitFromSchedule ? classes.dayPicker : ''}`}
                                            elevation={1}
                                            onClick={this.props.visitFromSchedule && (() => this.props.visitFromSchedule(engineerContactCode, moment(dateArray[5]).format('YYYY-MM-DD 00:00')))}
                                        >
                                            <Paper
                                                className={classes.dateContainer}
                                                elevation={0}
                                            >
                                                <div className={classes.dateDay}>
                                                    {moment(dateArray[5]).format('dddd')}
                                                </div>
                                                <div className={classes.dateDate}>
                                                    {moment(dateArray[5]).format('DD/MM/YYYY')}
                                                </div>
                                                {!engineerVisitsLoading && (engineerVisits.filter((visit) => moment(visit.scheduledVisitDateTime).format('YYYY-MM-DD') === dateArray[5]) || []).length > 0
                                                    ? (
                                                        <IconButton onClick={() => this.handleCuttingSheetClick(dateArray[5])} className={classes.assignmentButton} aria-label="Cut List"><AssignmentIcon className={classes.assignmentIcon} /></IconButton>
                                                    )
                                                    : ''}

                                                {!engineerVisitsLoading
                                                    ? (
                                                        <div className={`${classes.totalInstallationAmount} ${
                                                            1 === 1
                                                            // engineerVisits.filter(visit => moment(visit.scheduledVisitDateTime).format('YYYY-MM-DD') === date).filter(visit => !visit.points).length === 0
                                                                ? (classes.green)
                                                                : (classes.amber)
                                                        }`}
                                                        >
                                                            £
                                                            {!engineerVisitsLoading
                                                                && engineerVisits.filter((visit) => moment(visit.scheduledVisitDateTime).format('YYYY-MM-DD') === dateArray[5]).map((visit) => visit.visitItems.reduce((acc, visitItem) => acc + visitItem.agreementItem.installationAmount, 0)).reduce((acc, installationAmount) => acc + installationAmount, 0).toFixed(2)}
                                                        </div>
                                                    )
                                                    : ''}

                                                {!engineerVisitsLoading
                                                                && engineerVisits.filter((visit) => moment(visit.scheduledVisitDateTime).format('YYYY-MM-DD') === dateArray[5]).length > 0
                                                    ? (
                                                        <div
                                                            className={`${classes.totalDailyPoints} ${
                                                                engineerVisits.filter((visit) => moment(visit.scheduledVisitDateTime).format('YYYY-MM-DD') === dateArray[5]).filter((visit) => !visit.points).length === 0
                                                                    ? (classes.green)
                                                                    : (classes.amber)
                                                            }`}
                                                        >
                                                            {
                                                                engineerVisits.filter((visit) => moment(visit.scheduledVisitDateTime).format('YYYY-MM-DD') === dateArray[5]).filter((visit) => !visit.points).length === 0
                                                                    ? (engineerVisits.filter((visit) => moment(visit.scheduledVisitDateTime).format('YYYY-MM-DD') === dateArray[5]).reduce((acc, visit) => (acc + visit.points), 0))
                                                                    : '?'
                                                            }
                                                        </div>
                                                    )
                                                    : ''}
                                            </Paper>

                                            {!engineerVisitsLoading
                                                ? engineerVisits.filter((visit) => moment(visit.scheduledVisitDateTime).format('YYYY-MM-DD') === dateArray[5]).map((visit) => (
                                                    <Paper
                                                        onMouseOver={!this.props.visitFromSchedule && (() => this.handleMouseOverVisit(visit.code))}
                                                        onMouseLeave={!this.props.visitFromSchedule && (this.handleMouseLeaveVisit)}
                                                        onClick={!this.props.visitFromSchedule && (() => this.handleVisitClick(visit.contactCode, visit.code))}
                                                        className={classNames(
                                                            `${classes.visit} ${visit.status === 'INVITE' ? classes.invite : ''}`,
                                                            {
                                                                [classes.highlightBlue]: currentVisitCode === visit.code,
                                                            },
                                                            {
                                                                [classes.points2]: visit.points == null,
                                                            },
                                                            {
                                                                [classes.points1]: visit.points === 1,
                                                            },
                                                            {
                                                                [classes.points2]: visit.points === 2,
                                                            },
                                                            {
                                                                [classes.points3]: visit.points === 3,
                                                            },
                                                            {
                                                                [classes.points4]: visit.points === 4,
                                                            },
                                                            {
                                                                [classes.points5]: visit.points === 5,
                                                            },
                                                            {
                                                                [classes.points5]: visit.points === 6,
                                                            },
                                                        )}
                                                        elevation={0}
                                                        key={visit.code}
                                                    >
                                                        <div className={classes.visitTopBar}>
                                                            {`${visit.contact.firstname} ${visit.contact.surname}`}
                                                        </div>
                                                        {visit.visitItems.map((visitItem) => (
                                                            <div className={classes.visitItemList}>
                                                                {visitItem.agreementItem.quantity}
                                                                {' x '}
                                                                {visitItem.agreementItem.product.modelNo}
                                                            </div>
                                                        ))}
                                                        <div
                                                            className={classNames(
                                                                classes.visitPostcodeZone,
                                                                {
                                                                    [classes.postcodeClose]: visit.contact.address.postcodeZone === postcodeZone,
                                                                },
                                                                // ${visit.contact.address.postcodeArea === postcodeArea ? classes.postcodeClose : ''}
                                                                // ${visit.contact.address.postcodeDistrict === postcodeDistrict ? classes.postcodeMatch : ''}
                                                            )}
                                                        >
                                                            {visit.contact.address.postcodeZone}
                                                        </div>
                                                        <div className={classes.installationFee}>
                                                            £
                                                            {visit.visitItems.reduce((acc, visitItem) => acc + visitItem.agreementItem.installationAmount, 0).toFixed(2)}
                                                        </div>
                                                        <div
                                                            className={classNames(classes.visitPoints, { [classes.pointsBlue]: visit.points }, { [classes.amber]: !visit.points })}
                                                        >
                                                            {visit.points ? visit.points : '?'}
                                                        </div>
                                                    </Paper>
                                                ))
                                                : (
                                                    <div className={classes.progressContainer}>
                                                        <CircularProgress className={classes.progress} variant="indeterminate" />
                                                    </div>
                                                )}
                                        </Paper>
                                        <Paper
                                            className={`${classes.day} ${classes.sunday} ${this.props.visitFromSchedule ? classes.dayPicker : ''}`}
                                            elevation={1}
                                            onClick={this.props.visitFromSchedule && (() => this.props.visitFromSchedule(engineerContactCode, moment(dateArray[6]).format('YYYY-MM-DD 00:00')))}
                                        >
                                            <Paper
                                                className={classes.dateContainer}
                                                elevation={0}
                                            >
                                                <div className={classes.dateDay}>
                                                    {moment(dateArray[6]).format('dddd')}
                                                </div>
                                                <div className={classes.dateDate}>
                                                    {moment(dateArray[6]).format('DD/MM/YYYY')}
                                                </div>
                                                {!engineerVisitsLoading
                                                    ? (
                                                        <div className={`${classes.totalInstallationAmount} ${
                                                            1 === 1
                                                            // engineerVisits.filter(visit => moment(visit.scheduledVisitDateTime).format('YYYY-MM-DD') === date).filter(visit => !visit.points).length === 0
                                                                ? (classes.green)
                                                                : (classes.amber)
                                                        }`}
                                                        >
                                                            £
                                                            {!engineerVisitsLoading
                                                                && engineerVisits.filter((visit) => moment(visit.scheduledVisitDateTime).format('YYYY-MM-DD') === dateArray[6]).map((visit) => visit.visitItems.reduce((acc, visitItem) => acc + visitItem.agreementItem.installationAmount, 0)).reduce((acc, installationAmount) => acc + installationAmount, 0).toFixed(2)}
                                                        </div>
                                                    )
                                                    : ''}

                                                {!engineerVisitsLoading
                                                                && engineerVisits.filter((visit) => moment(visit.scheduledVisitDateTime).format('YYYY-MM-DD') === dateArray[6]).length > 0
                                                    ? (
                                                        <div
                                                            className={`${classes.totalDailyPoints} ${
                                                                engineerVisits.filter((visit) => moment(visit.scheduledVisitDateTime).format('YYYY-MM-DD') === dateArray[6]).filter((visit) => !visit.points).length === 0
                                                                    ? (classes.green)
                                                                    : (classes.amber)
                                                            }`}
                                                        >
                                                            {
                                                                engineerVisits.filter((visit) => moment(visit.scheduledVisitDateTime).format('YYYY-MM-DD') === dateArray[6]).filter((visit) => !visit.points).length === 0
                                                                    ? (engineerVisits.filter((visit) => moment(visit.scheduledVisitDateTime).format('YYYY-MM-DD') === dateArray[6]).reduce((acc, visit) => (acc + visit.points), 0))
                                                                    : '?'
                                                            }
                                                        </div>
                                                    )
                                                    : ''}
                                            </Paper>

                                            {!engineerVisitsLoading
                                                ? engineerVisits.filter((visit) => moment(visit.scheduledVisitDateTime).format('YYYY-MM-DD') === dateArray[6]).map((visit) => (
                                                    <>
                                                        <Paper
                                                            onMouseOver={!this.props.visitFromSchedule && (() => this.handleMouseOverVisit(visit.code))}
                                                            onMouseLeave={!this.props.visitFromSchedule && (this.handleMouseLeaveVisit)}
                                                            onClick={!this.props.visitFromSchedule && (() => this.handleVisitClick(visit.contactCode, visit.code))}
                                                            className={classNames(
                                                                `${classes.visit} ${visit.status === 'INVITE' ? classes.invite : ''}`,
                                                                {
                                                                    [classes.highlightBlue]: currentVisitCode === visit.code,
                                                                },
                                                                {
                                                                    [classes.points2]: visit.points == null,
                                                                },
                                                                {
                                                                    [classes.points1]: visit.points === 1,
                                                                },
                                                                {
                                                                    [classes.points2]: visit.points === 2,
                                                                },
                                                                {
                                                                    [classes.points3]: visit.points === 3,
                                                                },
                                                                {
                                                                    [classes.points4]: visit.points === 4,
                                                                },
                                                                {
                                                                    [classes.points5]: visit.points === 5,
                                                                },
                                                                {
                                                                    [classes.points5]: visit.points === 6,
                                                                },
                                                            )}
                                                            elevation={0}
                                                            key={visit.code}
                                                        >
                                                            <div className={classes.visitTopBar}>
                                                                {`${visit.contact.firstname} ${visit.contact.surname}`}
                                                            </div>

                                                            {visit.visitItems.map((visitItem) => (
                                                                <div className={classes.visitItemList}>
                                                                    {visitItem.agreementItem.quantity}
                                                                    {' x '}
                                                                    {visitItem.agreementItem.product.modelNo}
                                                                </div>
                                                            ))}
                                                            <div
                                                                className={classNames(
                                                                    classes.visitPostcodeZone,
                                                                    {
                                                                        [classes.postcodeClose]: visit.contact.address.postcodeZone === postcodeZone,
                                                                    },
                                                                // ${visit.contact.address.postcodeArea === postcodeArea ? classes.postcodeClose : ''}
                                                                // ${visit.contact.address.postcodeDistrict === postcodeDistrict ? classes.postcodeMatch : ''}
                                                                )}
                                                            >
                                                                {visit.contact.address.postcodeZone}
                                                            </div>
                                                            <div className={classes.installationFee}>
                                                                £
                                                                {visit.visitItems.reduce((acc, visitItem) => acc + visitItem.agreementItem.installationAmount, 0).toFixed(2)}
                                                            </div>
                                                            <div
                                                                className={classNames(classes.visitPoints, { [classes.pointsBlue]: visit.points }, { [classes.amber]: !visit.points })}
                                                            >
                                                                {visit.points ? visit.points : '?'}
                                                            </div>
                                                        </Paper>
                                                    </>
                                                ))
                                                : (
                                                    <div className={classes.progressContainer}>
                                                        <CircularProgress className={classes.progress} variant="indeterminate" />
                                                    </div>
                                                )}
                                        </Paper>
                                    </Grid>

                                </Grid>
                            </Grid>

                            {currentAdHocVisit
                            && (
                                <>
                                    <Dialog
                                        open={showAdHocDialog}
                                        onClose={this.handleCloseAdHocDialogClick}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogContent className={classes.dialogContent}>
                                            <div className={classes.dialogContentContainer}>
                                                <Typography variant="h6" gutterBottom className={classes.dialogTitle}>
                                                    Ad Hoc Visit Details
                                                </Typography>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <HybridTextField label="Company" text={currentAdHocVisit.adHocCompany} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <HybridTextField label="Payment Method" text={currentAdHocVisit.adHocPaymentMethod} />
                                                    </Grid>
                                                </Grid>
                                                <Divider className={classes.dialogDivider} />
                                                <Grid container spacing={0}>
                                                    <Grid item xs={6}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField label="Name" text={`${currentAdHocVisit.contact.firstname} ${currentAdHocVisit.contact.surname}`} />

                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <HybridTextField label="Address" text={[currentAdHocVisit.contact.address.address1, currentAdHocVisit.contact.address.address2, currentAdHocVisit.contact.address.townCity, currentAdHocVisit.contact.address.county].filter((e) => e === 0 || e).join('\n')} />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={0}>
                                                                <Grid item xs={4}>
                                                                    <HybridTextField label="Postcode" text={currentAdHocVisit.contact.address.postcode} />
                                                                </Grid>
                                                                <Grid item xs={8}>
                                                                    <HybridTextField label="Country" text={currentAdHocVisit.contact.address.country} />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <Grid item xs={12}>
                                                            <HybridTextField label="Home Telephone" text={currentAdHocVisit.contact.homeTelephoneNumber} />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <HybridTextField label="Mobile Telephone" text={currentAdHocVisit.contact.mobileTelephoneNumber} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Divider className={classes.dialogDivider} />
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <HybridTextField label="Delivery Date" text={moment(currentAdHocVisit.scheduledVisitDateTime).format('DD/MM/YYYY')} />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <HybridTextField label="Operative" text={currentAdHocVisit.scheduledEngineer} />
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <HybridTextField label="Points" text={currentAdHocVisit.points} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <HybridTextField label="Note" text={currentAdHocVisit.adHocNote} />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <HybridTextField label="Installation/Delivery Fee" text={`£${currentAdHocVisit.adHocFee.toFixed(2)}`} />
                                                    </Grid>
                                                </Grid>
                                                <Divider className={classes.dialogDivider} />
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12} className={classes.adHocSignature}>
                                                        <HybridTextField label="Customer Signature" />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <HybridTextField label="Date" />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </DialogContent>
                                        <DialogActions className={classes.dialogActions}>
                                            <Button
                                                onClick={this.handleCloseAdHocDialogClick}
                                                color="primary"
                                                autoFocus
                                            >
                                                Close
                                            </Button>
                                        </DialogActions>
                                        <MenuIcon
                                            className={classes.adHocMenuIcon}
                                            onClick={this.handleAdHocMenuClick}
                                        />
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={adHocAnchor}
                                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                            keepMounted
                                            open={Boolean(adHocAnchor)}
                                            onClose={this.handleAdHocMenuClose}
                                            classes={{ paper: classes.menu }}
                                            disableAutoFocusItem
                                        >
                                            <MenuItem disabled onClick={() => { this.handleAdHocMenuClose(); }}>Edit Details</MenuItem>
                                            <Divider className={classes.menuDivider} />
                                            <MenuItem disabled onClick={() => { this.handleAdHocMenuClose(); }}>Reschedule Visit</MenuItem>
                                            <MenuItem disabled={currentAdHocVisit.status === 'COMPLETE'} onClick={this.handleCancelVisitClick}>Cancel Visit</MenuItem>
                                            <MenuItem disabled={currentAdHocVisit.status === 'COMPLETE'} onClick={this.handleMarkVisitComplete}>Mark Complete</MenuItem>
                                        </Menu>
                                    </Dialog>

                                    <Dialog
                                        open={showCancelVisitDialog}
                                        onClose={this.handleCloseCancelVisitDialogClick}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogContent className={classes.dialogContent}>
                                            <div className={classes.dialogContentContainer}>
                                                <>
                                                    <Typography variant="h6" gutterBottom>
                                                        Cancel Visit
                                                    </Typography>
                                                    <DialogContentText>
                                                        Are you sure you want to cancel this visit?
                                                    </DialogContentText>
                                                    <DialogContentText>
                                                        Customer will be notified by SMS and email if you proceed.
                                                    </DialogContentText>
                                                    <Grid
                                                        container
                                                        spacing={0}
                                                        direction="column"
                                                        alignItems="center"
                                                        justify="center"
                                                    >
                                                        <Grid item xs={12}>
                                                            <Button
                                                                className={classes.dialogButton}
                                                                disabled={false}
                                                                onClick={this.handleCancelVisit}
                                                            >
                                                                YES
                                                            </Button>
                                                        </Grid>
                                                    </Grid>

                                                </>
                                            </div>
                                        </DialogContent>
                                        <DialogActions className={classes.dialogActions}>
                                            <Button
                                                onClick={this.handleCloseCancelVisitDialogClick}
                                                color="primary"
                                                autoFocus
                                            >
                                                Close
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </>
                            )}

                            <Dialog
                                open={showCreateAdHocDialog}
                                onClose={this.handleCreateAdHocCloseDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>
                                        <>
                                            <Typography variant="h6" gutterBottom>
                                                New Ad Hoc Visit
                                            </Typography>
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                    >
                                                        <InputLabel htmlFor="adHocCompany" className={classes.inputLabel}>Company</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateAdHocVisitData}
                                                                    name="adHocCompany"
                                                                    id="adHocCompany"
                                                                />
                                                            )}
                                                            value={adHocVisitForm.adHocCompany || ''}
                                                        >
                                                            {adHocCompanies.map((company) => (
                                                                [
                                                                    <MenuItem
                                                                        key={company}
                                                                        value={company}
                                                                    >
                                                                        {company}
                                                                    </MenuItem>,
                                                                ]
                                                            ))}
                                                        </Select>
                                                        {errors.adHocCompany && (
                                                            <FormHelperText>{errors.adHocCompany}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                    >
                                                        <InputLabel htmlFor="adHocPaymentMethod" className={classes.inputLabel}>Payment Method</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateAdHocVisitData}
                                                                    name="adHocPaymentMethod"
                                                                    id="adHocPaymentMethod"
                                                                />
                                                            )}
                                                            value={adHocVisitForm.adHocPaymentMethod || ''}
                                                        >
                                                            {adHocPaymentMethods.map((adHocPaymentMethod) => (
                                                                [
                                                                    <MenuItem
                                                                        key={adHocPaymentMethod}
                                                                        value={adHocPaymentMethod}
                                                                    >
                                                                        {adHocPaymentMethod}
                                                                    </MenuItem>,
                                                                ]
                                                            ))}
                                                        </Select>
                                                        {errors.adHocPaymentMethod && (
                                                            <FormHelperText>{errors.adHocPaymentMethod}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Divider className={classes.dialogDivider} />
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.title}
                                                    >
                                                        <InputLabel htmlFor="title" className={classes.inputLabel}>Title</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateAdHocVisitData}
                                                                    name="title"
                                                                    id="title"
                                                                />
                                                            )}
                                                            value={adHocVisitForm.title || ''}
                                                        >
                                                            <MenuItem value=""><em>None</em></MenuItem>
                                                            {titles.map((title, i) => (
                                                                <MenuItem
                                                                    key={i}
                                                                    value={title}
                                                                >
                                                                    {title}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.title && (
                                                            <FormHelperText>{errors.title}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="firstname"
                                                        id="firstname"
                                                        onChange={this.updateAdHocVisitData}
                                                        value={adHocVisitForm.firstname}
                                                        label="Firstname"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.firstname}
                                                        helperText={errors.firstname}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="surname"
                                                        id="surname"
                                                        onChange={this.updateAdHocVisitData}
                                                        value={adHocVisitForm.surname}
                                                        label="Surname"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.surname}
                                                        helperText={errors.surname}
                                                    />
                                                </Grid>
                                                <Divider className={classes.dialogDivider} />
                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="emailAddress"
                                                        id="emailAddress"
                                                        value={adHocVisitForm.emailAddress}
                                                        onChange={this.updateAdHocVisitData}
                                                        label="Email Address"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        error={!!errors.emailAddress}
                                                        helperText={errors.emailAddress}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="mobileTelephoneNumber"
                                                        id="mobileTelephoneNumber"
                                                        value={adHocVisitForm.mobileTelephoneNumber}
                                                        onChange={this.updateAdHocVisitData}
                                                        label="Mobile Telephone"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.mobileTelephoneNumber}
                                                        helperText={errors.mobileTelephoneNumber}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Divider className={classes.dialogDivider} />
                                            <Grid container spacing={0}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="postcode"
                                                        id="postcode"
                                                        value={adHocVisitForm.postcode}
                                                        onChange={this.updateAdHocVisitData}
                                                        label="Lookup Postcode"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        error={lookupAddresses === []}
                                                        helperText={lookupAddresses === [] ? 'No addresses found' : ''}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Button
                                                        className={classes.lookupButton}
                                                        onClick={this.lookupPostcode}
                                                        disabled={adHocVisitForm.postcode.length < 3}
                                                    >
                                                        LOOKUP ADDRESS
                                                    </Button>
                                                </Grid>
                                                <Menu
                                                    id="simple-menu"
                                                    anchorEl={lookupAddressesEl}
                                                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                                    keepMounted
                                                    open={Boolean(lookupAddressesEl)}
                                                    onClose={this.handleCloseLookupAddressesMenu}
                                                    classes={{ paper: classes.menu }}
                                                    disableAutoFocusItem
                                                >
                                                    {lookupAddresses.map((lookupAddress, i) => (
                                                        <MenuItem
                                                            key={lookupAddress.address1}
                                                            value={lookupAddress}
                                                            onClick={() => this.handleLookupAddressClick(i)}
                                                        >
                                                            {lookupAddress.address1}
                                                        </MenuItem>
                                                    ))}
                                                </Menu>

                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="address1"
                                                        id="address1"
                                                        value={adHocVisitForm.address1}
                                                        onChange={this.updateAdHocVisitData}
                                                        label="Address"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.address1}
                                                        helperText={errors.address1}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="address2"
                                                        id="address2"
                                                        value={adHocVisitForm.address2}
                                                        onChange={this.updateAdHocVisitData}
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="townCity"
                                                        id="townCity"
                                                        value={adHocVisitForm.townCity}
                                                        onChange={this.updateAdHocVisitData}
                                                        label="Town/City"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.townCity}
                                                        helperText={errors.townCity}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.title}
                                                    >
                                                        <InputLabel htmlFor="county" className={classes.inputLabel}>County</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateAdHocVisitData}
                                                                    name="county"
                                                                    id="county"
                                                                />
                                                            )}
                                                            value={adHocVisitForm.county || ''}
                                                        >
                                                            <MenuItem value=""><em>None</em></MenuItem>
                                                            {counties.map((county, i) => (
                                                                <MenuItem
                                                                    key={i}
                                                                    value={county}
                                                                >
                                                                    {county}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.county && (
                                                            <FormHelperText>{errors.county}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        className={classes.formControl}
                                                        name="postcode"
                                                        id="postcode"
                                                        value={adHocVisitForm.postcode}
                                                        onChange={this.updateAdHocVisitData}
                                                        label="Postcode"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.postcode}
                                                        helperText={errors.postcode}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        error={!!errors.country}
                                                    >
                                                        <InputLabel htmlFor="country" className={classes.inputLabel}>Country</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateAdHocVisitData}
                                                                    name="country"
                                                                    id="country"
                                                                />
                                                            )}
                                                            value={adHocVisitForm.country || ''}
                                                        >
                                                            <MenuItem value=""><em>None</em></MenuItem>
                                                            {countries.map((country, i) => (
                                                                <MenuItem
                                                                    key={i}
                                                                    value={country}
                                                                >
                                                                    {country}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                        {errors.country && (
                                                            <FormHelperText>{errors.country}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Divider className={classes.dialogDivider} />
                                            <Grid container spacing={0}>
                                                <DatePicker
                                                    name="dateTime"
                                                    id="dateTime"
                                                    margin="normal"
                                                    label="Scheduled Date/Time"
                                                    value={adHocVisitForm.scheduledVisitDateTime}
                                                    onChange={this.handleAdHocVisitDateTimeChange}
                                                    fullWidth
                                                    variant="outlined"
                                                    format="DD/MM/YYYY"
                                                    invalidLabel="No limit"
                                                    autoOk
                                                />
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                    >
                                                        <InputLabel htmlFor="scheduledEngineerContactCode" className={classes.inputLabel}>Scheduled Operative</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateAdHocVisitData}
                                                                    name="scheduledEngineerContactCode"
                                                                    id="scheduledEngineerContactCode"
                                                                />
                                                            )}
                                                            value={adHocVisitForm.scheduledEngineerContactCode || ''}
                                                        >
                                                            {engineers.map((engineer) => (
                                                                [
                                                                    <MenuItem
                                                                        key={engineer.code}
                                                                        value={engineer.code}
                                                                    >
                                                                        {`${engineer.firstname} ${engineer.surname}`}
                                                                    </MenuItem>,
                                                                ]
                                                            ))}
                                                        </Select>
                                                        {errors.scheduledEngineerContactCode && (
                                                            <FormHelperText>{errors.scheduledEngineerContactCode}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl
                                                        className={classes.menuFormControl}
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                    >
                                                        <InputLabel htmlFor="points" className={classes.inputLabel}>Visit Points</InputLabel>
                                                        <Select
                                                            keyboard="true"
                                                            input={(
                                                                <OutlinedInput
                                                                    onChange={this.updateAdHocVisitData}
                                                                    name="points"
                                                                    id="points"
                                                                />
                                                            )}
                                                            value={adHocVisitForm.points || ''}
                                                        >
                                                            {visitPointsArray.map((visitPoint) => (
                                                                [
                                                                    <MenuItem
                                                                        key={visitPoint}
                                                                        value={visitPoint}
                                                                    >
                                                                        {visitPoint}
                                                                    </MenuItem>,
                                                                ]
                                                            ))}
                                                        </Select>
                                                        {errors.points && (
                                                            <FormHelperText>{errors.points}</FormHelperText>
                                                        )}
                                                    </FormControl>
                                                </Grid>
                                                <TextField
                                                    className={`${classes.formControl} ${classes.marginTop3}`}
                                                    name="adHocFee"
                                                    id="adHocFee"
                                                    value={adHocVisitForm.adHocFee}
                                                    onChange={this.updateAdHocVisitData}
                                                    label="Installation Amount"
                                                    margin="normal"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    error={!!errors.weeklyRentalAmount}
                                                    helperText={errors.weeklyRentalAmount}
                                                    InputProps={{
                                                        startAdornment:
                            <InputAdornment
                                disableTypography
                                className={classes.inputAdornment}
                                position="start"
                            >
                                £
                            </InputAdornment>,
                                                    }}
                                                />
                                                <Grid item xs={12}>
                                                    <TextField
                                                        onChange={this.updateAdHocVisitData}
                                                        className={classes.formControl}
                                                        name="adHocNote"
                                                        id="adHocNote"
                                                        label="Note"
                                                        margin="normal"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        multiline
                                                        rows="2"
                                                        // inputRef={(input) => {
                                                        //     if (input != null) {
                                                        //         input.focus();
                                                        //     }
                                                        // }}
                                                    />
                                                </Grid>

                                            </Grid>
                                            <Grid
                                                container
                                                spacing={0}
                                                direction="column"
                                                alignItems="center"
                                                justify="center"
                                            >
                                                <Grid item xs={12}>
                                                    <Button
                                                        className={classes.dialogButton}
                                                        onClick={this.handleSaveNewAdHocVisit}
                                                        disabled={disableEditAdHocSaveButton}
                                                    >
                                                        SAVE
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </>
                                    </div>
                                </DialogContent>
                                <Divider />
                                <DialogActions className={classes.dialogActions}>
                                    <Button
                                        onClick={this.handleCreateAdHocCloseDialogClick}
                                        color="primary"
                                        autoFocus
                                    >
                                        Close
                                    </Button>
                                </DialogActions>
                            </Dialog>

                            {/* <Dialog
                                open={showCuttingSheetDialog}
                                onClose={this.handleCloseCuttingSheetDialogClick}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                maxWidth="lg"
                            >
                                <DialogContent className={classes.dialogContent}>
                                    <div className={classes.dialogContentContainer}>

                                        <CuttingSheet
                                            visits={engineerVisits}
                                            visitsLoading={engineerVisitsLoading}
                                        />
                                    </div>
                                </DialogContent>
                            </Dialog> */}
                            {/* <div className={`${classes.cuttingSheet} printable`}> */}
                            {cuttingSheetVisits
                                ? (
                                    <CuttingSheet
                                        visits={cuttingSheetVisits}
                                        visitsLoading={engineerVisitsLoading}
                                    />
                                )
                                : null}
                            {/* </div> */}
                        </>
                    )
                    : (
                        <div className={classes.progressContainer}>
                            <CircularProgress className={classes.progress} variant="indeterminate" />
                        </div>
                    )}
            </div>
        );
    }
}

export default withSnackbar(withStyles(styles)(EngineerSchedule));
